import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import globals from "../../globals";

export const Box = styled.div`
  margin: ${globals.metrics.defaultSpace};
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  position: relative;
  select {
    border: none;
    background-color: #fff;
    width: 100%;
    padding: 15px 10px;
    -webkit-appearance: none;
    color: ${globals.colors.primary};
    text-transform: uppercase;
    font-weight: bold;
  }
  i {
    color: ${globals.colors.secondary};
    font-size: 1.4rem;
    line-height: 1;
    position: absolute;
    right: 10px;
  }
`;

const SelectCategoria = ({ categorias, handleInputs }) => (
  <Box>
    <select onChange={handleInputs} name="categoria" id="categoria">
      <option disabled value="">
        Filtrar por Categoria
      </option>
      <option value="">Todos</option>
      {categorias
        && categorias.map(({ slug, nome }) => (
          <option key={slug} value={slug}>
            {nome}
          </option>
        ))}
    </select>
    <Icon type="down" />
  </Box>
);
export default SelectCategoria;
