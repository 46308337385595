/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { Creators as UtilsActions } from "store/ducks/utils";
import Header from "components/Header";
import api from "services/api";
import styled from "styled-components";
// import globals from "../../globals";

export const Banner = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: block;
`;
export const Content = styled.div`
  margin: 0 20px;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  padding: 15px;

  h1 {
    margin-top: 0;
    font-size: 1.55rem;
  }
`;

const NoticiaSingle = ({ match, ...rest }) => {
  const [dados, setDados] = useState({});

  const dispatch = useDispatch();
  const { toogleLoading } = UtilsActions;

  useEffect(() => {
    dispatch(toogleLoading());

    api.get(`noticias/v1/dados?id=${match.params.id}`).then(({ data }) => {
      setDados(data);
      dispatch(toogleLoading());
    });
  }, [match.params.id, dispatch, toogleLoading]);

  return (
    <>
      <Header title="Noticia" {...rest} interna />
      <Banner src={dados.thumb} alt={dados.titulo} />
      <Content>
        <h1>{dados.titulo}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(dados.content),
          }}
        />
      </Content>
    </>
  );
};

export default NoticiaSingle;
