import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "antd";
import { Link } from "react-router-dom";

import styled from "styled-components";
import userImg from "./user.png";
import globals from "../../globals";

export const Card = styled.div`
  margin: ${globals.metrics.defaultSpace};
  margin-top: 40px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  img {
    padding: 15px;
    width: 25%;
    height: 25%;
    border-radius: 100px;
    object-fit: cover;
  }
  .dados-user {
    padding: 10px;
    width: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    h2 {
      margin: 0;
      color: ${globals.colors.primary};
      font-size: 1.3rem;
    }
    span {
      margin: 0;
      font-size: 0.8rem;
      color: ${globals.colors.light};
    }
  }
  .button {
    width: 25%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    background-color: ${globals.colors.primary};
    color: #fff;
    text-decoration: none;
    text-align: center;
    i {
      font-size: 2rem;
    }
    span {
      font-size: 0.6rem;
    }
  }
`;

const HelloUser = () => {
  const { user } = useSelector(state => state.auth);
  return (
    <Card>
      <img src={userImg} alt="avatar" />
      <div className="dados-user">
        <h2>
          Olá,
          {user.nome && user.nome.split(" ")[0]}
        </h2>
        <span>
          Matrícula:
          {user.matricula}
        </span>
      </div>
      <Link to="/meu-cartao" className="button">
        <Icon type="credit-card" />
        <span>Acessar Cartão Virtual</span>
      </Link>
    </Card>
  );
};

export default HelloUser;
