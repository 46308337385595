import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Creators as AuthActions } from "store/ducks/auth";
import api from "services/api";
import logo from "assets/img/logo@2x.png";
import { StyledCard, Inner } from "./styles";

const Login = () => {
  const [inputs, setInputs] = useState({
    cpf: "",
    matricula: ""
  });

  const dispatch = useDispatch();
  const { isLogged } = useSelector(state => state.auth);

  const handleSubmit = (e) => {
    const { cpf, matricula } = inputs;
    e.preventDefault();
    api
      .post("utils/v1/auth", {
        cpf: cpf.replace(/[^0-9]/g, ""),
        matricula
      })
      .then(({ data: user }) => dispatch(AuthActions.login(user)))
      .catch(() => alert("Seu cadastro não foi encontrado"));
  };

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Inner>
        {isLogged && <Redirect to="/" />}
        <img src={logo} className="logo" alt="Logo" />
        <StyledCard>
          <form onSubmit={handleSubmit}>
            <input
              type="tel"
              name="cpf"
              id="cpf"
              placeholder="Informe o seu CPF"
              required
              value={inputs.cpf}
              onChange={handleInputs}
            />
            <input
              type="tel"
              name="matricula"
              id="matricula"
              placeholder="Informe a sua Matrícula"
              required
              value={inputs.matricula}
              onChange={handleInputs}
            />
            <span>*somente números</span>
            <button type="submit">Acessar</button>
          </form>
        </StyledCard>
      </Inner>
    </>
  );
};

export default Login;
