export default {
  colors: {
    primary: "#3E4095",
    secondary: "#F79633",
    light: "#8E8E93",
    dark: "#333333",
  },
  metrics: {
    defaultPadding: "15px",
    defaultSpace: "15px",
  },
};
