import React from "react";
import logo from "assets/img/logo@2x.png";
import styled from "styled-components";
import globals from "../../globals";

export const Container = styled.div`
  margin: ${globals.metrics.defaultSpace};
  padding: 20px;
  padding-bottom: 60px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    max-width: 200px;
  }
  h4 {
    margin-bottom: 0;
  }
  p {
    margin-top: 10px;
  }
`;

const Footer = () => (
  <Container>
    <img src={logo} alt="Logo" />
    <h4>Associação dos Gestores da Caixa Econômica Federal do Ceara</h4>
    <p>
      R. Sena Madureira, 800 - Centro, Fortaleza - CE
      <br />
      <b>(85) 99994-4955</b>
    </p>
  </Container>
);

export default Footer;
