import React from "react";
import styled from "styled-components";
import { Icon } from "antd";

import globals from "../../globals";

export const StyledHeader = styled.header`
  padding: 30px 20px;
  padding-bottom: 10px;
  background-color: ${globals.colors.primary};
  h1 {
    color: #fff;
    font-size: 2rem;
    margin: 0;
  }
  button {
    appearance: none;
    border: none;
    background: none;
    color: #fff;
    font-size: 1rem;
    padding: 0;
    margin-bottom: 10px;
  }
`;

const Header = ({ title, interna, history }) => (
  <>
    <StyledHeader>
      {interna && (
        <button type="button" onClick={() => history.goBack()}>
          <Icon type="left" />
          <span>Voltar</span>
        </button>
      )}
      <h1>{title}</h1>
    </StyledHeader>
  </>
);

export default Header;
