import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-styled-flexboxgrid";
import BeneficioItem from "../BeneficioItem";
import globals from "../../globals";

export const List = styled.div`
  margin: ${globals.metrics.defaultSpace};
`;

const BeneficioItemList = ({ beneficios }) => (
  <List>
    <Row>
      {beneficios.map(item => (
        <Col xs={6} md={3} key={item.id}>
          <BeneficioItem item={item} />
        </Col>
      ))}
    </Row>
  </List>
);
export default BeneficioItemList;
