import { createActions, createReducer } from "reduxsauce";
import localForage from "localforage";
/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  login: ["user", "token"],
  logout: [],
  toogleCheckingAuth: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  isCheckingAuth: true,
  isLogged: false,
  user: {},
};

const login = (state = INITIAL_STATE, action) => {
  const { user } = action;
  const newstate = {
    ...state,
    isCheckingAuth: false,
    user,
    isLogged: true,
  };
  localForage.setItem("agecefce_user", user);
  return newstate;
};

const logout = () => {
  localForage.removeItem("agecefce_user");
  return { ...INITIAL_STATE, isCheckingAuth: false };
};

const toogleCheckingAuth = (state = INITIAL_STATE) => ({
  ...state,
  isCheckingAuth: !state.isCheckingAuth,
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOGIN]: login,
  [Types.LOGOUT]: logout,
  [Types.TOOGLE_CHECKING_AUTH]: toogleCheckingAuth,
});
