import React from "react";
import { Icon } from "antd";

const IconLink = ({ link, icone, theme = "" }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    className="icon-link"
    href={link}
  >
    <Icon type={icone} theme={theme} />
  </a>
);

export default IconLink;
