/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Creators as UtilsActions } from "store/ducks/utils";
import { Creators as AuthActions } from "store/ducks/auth";
import localForage from "localforage";
import Header from "components/Header";
import HelloUser from "components/HelloUser";
import SliderNoticias from "components/SliderNoticias";
import SearchInput from "components/SearchInput";
// import Loading from "components/Loading";
import useDebounce from "helpers/useDebounce";
import api from "services/api";
import styled from "styled-components";
import globals from "../../globals";

export const ButtonLogout = styled.button`
  padding: 5px 20px;
  text-transform: uppercase;
  appearance: none;
  border: none;
  background-color: ${globals.colors.primary};
  color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
`;

const Home = ({ history }) => {
  const [inputs, setInputs] = useState({ query: "" });
  const [noticias, setNoticias] = useState([]);

  const debouncedInputs = useDebounce(inputs, 500);

  const dispatch = useDispatch();
  const { toogleLoading } = UtilsActions;
  const { logout } = AuthActions;

  useEffect(() => {
    if (debouncedInputs.query) {
      history.push(`/beneficios?q=${debouncedInputs.query}`);
    }
    // setResults([]);
  }, [history, debouncedInputs]);
  useEffect(() => {
    dispatch(toogleLoading());
    api
      .get("noticias/v1/list?qtd=3")
      .then(({ data }) => {
        setNoticias(data);
        localForage.setItem("agecefce_noticias", data);
      })
      .finally(() => dispatch(toogleLoading()));
  }, [dispatch, toogleLoading]);
  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Header title="Home" />
      <main>
        <HelloUser />
        <ButtonLogout onClick={() => dispatch(logout())} type="button">
          sair
        </ButtonLogout>
        {noticias[0] && <SliderNoticias noticias={noticias} />}
        <SearchInput value={inputs.query} handleInputs={handleInputs} />
      </main>
    </>
  );
};

export default Home;
