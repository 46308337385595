import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  toogleLoading: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  isLoading: false,
};

const toogleLoading = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: !state.isLoading,
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.TOOGLE_LOADING]: toogleLoading,
});
