/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { useSelector } from "react-redux";

import Header from "components/Header";
import styled from "styled-components";
import logo from "assets/img/logo-white@2x.png";
import bgCartao from "assets/img/bg-cartao@2x.png";
import globals from "../../globals";

export const Cartao = styled.div`
  margin: ${globals.metrics.defaultSpace} auto;
  @media screen and (max-width: 450px) {
    margin: ${globals.metrics.defaultSpace};
  }
  background-image: url(${bgCartao});
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 30px;
  max-width: 450px;
  border-radius: 20px;
  min-height: 230px;
  color: #fff;
  img {
    width: 150px;
    filter: saturate(0) brightness(1000);
  }
  .dados {
    text-align: right;
  }
  h3 {
    margin: 0;
    font-size: 1.3rem;
  }
  p {
    margin: 0;
    font-size: 0.9rem;
  }
`;

export const Observacao = styled.p`
  margin: ${globals.metrics.defaultSpace};
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
`;

const CartaoVirtualPage = ({ ...rest }) => {
  const { user } = useSelector(state => state.auth);

  return (
    <>
      <Header title="Meu Cartão" {...rest} interna />
      <Cartao>
        <img src={logo} alt="Logo" />
        <div className="dados">
          <h3>{user.nome}</h3>
          <p>
            Mat: {user.matricula} CPF: {user.cpf}
          </p>
        </div>
      </Cartao>
      <Observacao>
        Apresente seu cartão no conveniado e tenha acesso a condições especiais
      </Observacao>
    </>
  );
};

export default CartaoVirtualPage;
