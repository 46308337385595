import styled from "styled-components";
import { Card } from "components/HelloUser";
import globals from "../../globals";

export const StyledCard = styled(Card)`
  margin: ${globals.metrics.defaultSpace};
  width: 100%;
  padding: 40px;
  form {
    display: flex;
    flex-flow: column;
    width: 100%;
    input {
      border-radius: 5px;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border: 1px solid ${globals.colors.light};
    }
    span {
      font-size: 0.7rem;
      margin: 5px 0;
    }
    button {
      padding: 10px;
      background-color: ${globals.colors.secondary};
      color: #fff;
      border-radius: 5px;
      display: inline-block;
      border: none;
    }
  }
`;

export const Inner = styled.div`
  min-height: ${window.innerHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .logo {
    width: 200px;
  }
`;
