import React from "react";
import { Icon } from "antd";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import globals from "../../globals";

export const NavItem = styled(NavLink)`
  padding: 10px;
  text-decoration: none;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: ${globals.colors.light};
  align-items: center;
  &.selected {
    background: ${globals.colors.primary};
    color: #fff;
  }
  h2 {
    margin: 0;
    font-size: 0.6rem;
    font-weight: 400;
  }
  i {
    font-size: 1.5rem;
    line-height: 0.9;
  }
`;

const NavBarItem = ({ title, icon, to }) => (
  <NavItem activeClassName="selected" exact to={to}>
    <Icon type={icon} />
    <h2>{title}</h2>
  </NavItem>
);

export default NavBarItem;
