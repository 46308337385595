import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import { Link } from "react-router-dom";
import LogoParceiro from "components/LogoParceiro";
import globals from "../../globals";

export const Item = styled(Link)`
  text-decoration: none;
  padding: 20px 10px;
  background-color: ${globals.colors.primary};
  margin-top: 20px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .logo {
    padding: 10px;
    background-color: #fff;
    border-radius: 100px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
  }
  h3 {
    margin: 0;
    font-size: 1rem;
    text-align: center;
    margin-top: 10px;
  }
  .location {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      margin-left: 5px;
      font-size: 0.7rem;
    }
  }
`;

const BeneficioItem = ({ item }) => (
  <Item to={`/beneficio/${item.id}`}>
    <LogoParceiro image={item.logo} />
    <h3>{item.nome}</h3>
    <div className="location">
      <Icon type="environment" />
      <p>{item.cidade.titulo}</p>
    </div>
  </Item>
);

export default BeneficioItem;
