import React from "react";
import { Route as ReactRoute, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "components/Loading";

const RouteContainer = ({ isProtected, location, ...others }) => {
  const { isLogged, isCheckingAuth } = useSelector(state => state.auth);

  if (isCheckingAuth) {
    return <Loading />;
  }
  if (isLogged && location.pathname === "/login") {
    return <Redirect to="/" />;
  }
  if (!isLogged && isProtected) {
    return <Redirect to="/login" />;
  }
  return <ReactRoute {...others} />;
};

export default RouteContainer;
