import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import globals from "../../globals";

export const Box = styled.div`
  margin: ${globals.metrics.defaultSpace};
  margin-top: 60px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  input {
    border: none;
    background-color: #fff;
    width: 100%;
    padding: 15px 10px;
    ::placeholder {
      color: ${globals.colors.primary};
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  button {
    appearance: none;
    border: none;
    background-color: #fff;
    i {
      color: ${globals.colors.secondary};
      font-size: 1.4rem;
      line-height: 1;
    }
  }
`;

const SearchInput = ({ handleInputs, value }) => (
  <Box>
    <input
      name="query"
      onChange={handleInputs}
      value={value}
      type="text"
      placeholder="Pesquisar Benefícios"
    />
    <button type="button">
      <Icon type="search" />
    </button>
  </Box>
);
export default SearchInput;
