import styled from "styled-components";
import globals from "../../globals";

export const Card = styled.div`
  margin: ${globals.metrics.defaultSpace};
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    background-color: ${globals.colors.primary};
    color: #fff;
    border-radius: 10px 10px 0 0;
    h2 {
      margin-left: 5px;
      font-size: 1.3rem;
      max-width: 70%;
    }
  }
  .content {
    padding: 30px 20px;
    .dados {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      i {
        font-size: 1.6rem;
        margin-right: 5px;
      }
      p {
        margin: 3px 0;
        font-size: 1.1rem;
      }
    }
    p {
      margin: 0;
      margin-top: 15px;
      font-size: 0.9rem;
    }
  }
  .links-beneficios {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .icon-link {
      padding: 10px;
      background: ${globals.colors.primary};
      color: #fff;
      margin: 5px;
      border-radius: 100px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      i {
        line-height: 1;
      }
    }
  }
`;

export const TitleOutros = styled.h3`
  text-align: center;
  margin-top: 30px;
`;
