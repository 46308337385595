import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import createHistory from "history/createBrowserHistory";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "sass/index.scss";
import "normalize.css";
import store from "./store";

ReactGA.initialize("UA-150816732-1");

const history = createHistory();
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
