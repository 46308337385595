import React from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { Link } from "react-router-dom";
import globals from "../../globals";

export const Slider = styled.div`
  margin: ${globals.metrics.defaultSpace};
  img {
    display: block;
    width: 100%;
    height: 50vh;
    margin-top: 20px;
    object-fit: cover;
    max-height: 200px;
    border-radius: 5px 5px 0 0;
  }
  .slider-control-centerright,
  .slider-control-centerleft {
    display: none;
  }
  .slider-control-bottomcenter {
    bottom: -40px !important;
  }
  .slide {
    text-decoration: none;
  }
  .titulo {
    background-color: ${globals.colors.primary};
    padding: 10px;
    color: #fff;
    border-radius: 0 0 5px 5px;
    text-decoration: none;
  }
`;

const SliderNoticias = ({ noticias }) => (
  <Slider>
    <Carousel slidesToShow={1} wrapAround cellAlign="center">
      {noticias.map(({ id, thumb, titulo }) => (
        <Link to={`/noticia/${id}`} className="slide" key={id}>
          <img src={thumb} alt="avatar" />
          <div className="titulo">{titulo}</div>
        </Link>
      ))}
    </Carousel>
  </Slider>
);

export default SliderNoticias;
