/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { Creators as UtilsActions } from "store/ducks/utils";
import Header from "components/Header";
import BeneficioItemList from "components/BeneficioItemList";
import api from "services/api";
import { Icon } from "antd";
import LogoParceiro from "components/LogoParceiro";
import IconLink from "./IconLink";
import { Card, TitleOutros } from "./styles";

const BeneficiosSingle = ({ match, ...rest }) => {
  const [beneficios, setBeneficios] = useState([]);
  const [dados, setDados] = useState({});

  const dispatch = useDispatch();
  const { toogleLoading } = UtilsActions;

  useEffect(() => {
    dispatch(toogleLoading());

    api.post("beneficios/v1/list").then(({ data }) => {
      const id = parseInt(match.params.id, 10);
      const newData = data.filter(item => item.id !== id);
      setBeneficios(newData);
    });
    api.get(`beneficios/v1/dados?id=${match.params.id}`).then(({ data }) => {
      setDados(data);
      dispatch(toogleLoading());
    });
  }, [match.params.id, dispatch, toogleLoading]);

  return (
    <>
      <Header title="Benefício" {...rest} interna />
      {dados.cidade && (
        <Card>
          <header>
            <LogoParceiro image={dados.logo} />
            <h2>{dados.nome}</h2>
          </header>
          <div className="content">
            <div className="dados">
              <Icon type="environment" />
              <p>{dados.cidade.titulo}</p>
            </div>
            {dados.telefone && (
              <div className="dados">
                <Icon type="phone" />
                <p>{dados.telefone}</p>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dados.descricao),
              }}
            />
            <div className="links-beneficios center-xs">
              {dados.facebook && (
                <IconLink
                  icone="facebook"
                  theme="filled"
                  link={dados.facebook}
                />
              )}
              {dados.instagram && (
                <IconLink icone="instagram" link={dados.instagram} />
              )}
              {dados.url_site && (
                <IconLink icone="link" link={dados.url_site} />
              )}
            </div>
          </div>
        </Card>
      )}
      <TitleOutros>Outros Benefícios</TitleOutros>
      <BeneficioItemList beneficios={beneficios} />
    </>
  );
};

export default BeneficiosSingle;
