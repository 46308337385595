import React from "react";
import styled from "styled-components";

export const Logo = styled.div`
  padding: 10px;
  background-color: #fff;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  img {
    max-height: 100%;
    margin: 0 auto;
  }
`;

const LogoParceiro = ({ image }) => (
  <Logo>
    <img src={image} alt="logo" />
  </Logo>
);

export default LogoParceiro;
