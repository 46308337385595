/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

import { useSelector } from "react-redux";
import Header from "components/Header";

const PesquisaPage = ({ ...rest }) => {
  const {
    user: { cpf, matricula },
  } = useSelector((state) => state.auth);

  return (
    <>
      <Header title="Votação" {...rest} interna />
      <div
        style={{
          height: "80vh",
          position: "relative",
        }}
      >
        <iframe
          style={{
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
          title="votação assembléia"
          src={`https://docs.google.com/forms/d/e/1FAIpQLSeNotEp8k4Dr-c-SqHyj3gibfalTF2MihMVc3VKW0q_aHEsvQ/viewform?usp=pp_url&entry.993521509=${cpf}&entry.520883018=${matricula}`}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Carregando…
        </iframe>
      </div>
    </>
  );
};

export default PesquisaPage;
