import React from "react";
import styled from "styled-components";
import NavBarItem from "./NavBarItem";
import globals from "../../globals";

export const FixedNav = styled.div`
  width: 100%;
  max-width: 600px;
  left: calc(50% - 300px);
  @media screen and (max-width: 599px) {
    left: 0;
  }
  position: fixed;
  bottom: 0;
  background-color: #f5f5f5;
  border-top: 1px solid ${globals.colors.light};
  display: flex;
`;

const NavBar = () => (
  <FixedNav>
    <NavBarItem title="Início" to="/" icon="home" />
    <NavBarItem title="Votação" to="/pesquisa2021" icon="file-search" />
    <NavBarItem title="Cartão Virtual" to="/meu-cartao" icon="credit-card" />
    <NavBarItem title="Notícias" to="/noticias" icon="read" />
    <NavBarItem title="Benefícios" to="/beneficios" icon="appstore" />
  </FixedNav>
);

export default NavBar;
