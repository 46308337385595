import React, { useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import localForage from "localforage";
import ReactGA from "react-ga";

import NavBar from "components/NavBar";
import Footer from "components/Footer";
import Loading from "components/Loading";
import { Creators as AuthActions } from "store/ducks/auth";
// import { Creators as UtilsActions } from "store/ducks/utils";
import RouteContainer from "routes/RouteContainer";
import Home from "./pages/Home";
import CartaoVirtual from "./pages/CartaoVirtual";
import BeneficiosArchive from "./pages/BeneficiosArchive";
import NoticiasArchive from "./pages/NoticiasArchive";
import Pesquisa from "./pages/Pesquisa";
import BeneficiosSingle from "./pages/BeneficiosSingle";
import NoticiaSingle from "./pages/NoticiaSingle";
import Login from "./pages/Login";

function App() {
  const { isLogged } = useSelector(state => state.auth);
  const { isLoading } = useSelector(state => state.utils);

  const dispatch = useDispatch();
  window.localforage = localForage;
  useEffect(() => {
    localForage.getItem("agecefce_user", (err, value) => {
      if (value) {
        dispatch(AuthActions.login(value));
      } else {
        dispatch(AuthActions.toogleCheckingAuth());
      }
      // dispatch(UtilsActions.toogleLoading());
    });
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <main>
      <Switch>
        <RouteContainer isProtected exact path="/" component={Home} />
        <RouteContainer
          isProtected
          path="/meu-cartao"
          component={CartaoVirtual}
        />
        <RouteContainer path="/login" component={Login} />
        <RouteContainer path="/beneficios" component={BeneficiosArchive} />
        <RouteContainer
          isProtected
          path="/beneficio/:id"
          component={BeneficiosSingle}
        />
        <RouteContainer
          isProtected
          path="/noticias"
          component={NoticiasArchive}
        />
        <RouteContainer
          isProtected
          path="/noticia/:id"
          component={NoticiaSingle}
        />
        <RouteContainer isProtected path="/pesquisa2021" component={Pesquisa} />
      </Switch>
      {isLoading && <Loading />}
      {!isLogged && <Redirect to="/login" />}
      {isLogged && <Footer />}
      {isLogged && <NavBar />}
    </main>
  );
}

export default App;
