/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "components/Header";
import api from "services/api";
import { useDispatch } from "react-redux";
import localForage from "localforage";
import { Creators as UtilsActions } from "store/ducks/utils";
import styled from "styled-components";
import globals from "../../globals";

export const ListCards = styled.div`
  margin: 20px;
`;
export const Card = styled(Link)`
  margin-top: 15px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  text-decoration: none;
  img {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 150px;
    object-fit: cover;
    display: block;
  }
  .content {
    padding: 15px;
    h2 {
      color: ${globals.colors.dark};
      margin: 0;
      font-size: 1.3rem;
    }
    p {
      color: ${globals.colors.primary};
      font-size: 0.85rem;
    }
  }
`;

const NoticiasArchive = ({ location, ...rest }) => {
  const [noticias, setNoticias] = useState([]);

  const dispatch = useDispatch();
  const { toogleLoading } = UtilsActions;

  useEffect(() => {
    dispatch(toogleLoading());
    api
      .get("noticias/v1/list")
      .then(({ data }) => {
        setNoticias(data);
        localForage.setItem("agecefce_noticias", data);
      })
      .finally(() => dispatch(toogleLoading()));
  }, [dispatch, toogleLoading]);

  return (
    <>
      <Header title="Noticias" {...rest} interna />
      <ListCards>
        {noticias
          && noticias.map(({ id, thumb, titulo }) => (
            <Card key={id} to={`/noticia/${id}`}>
              <img src={thumb} alt={titulo} />
              <div className="content">
                <h2>{titulo}</h2>
                <p>Saiba mais</p>
              </div>
            </Card>
          ))}
      </ListCards>
    </>
  );
};

export default NoticiasArchive;
