/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import Header from "components/Header";
import api from "services/api";
import { useDispatch } from "react-redux";
import { Creators as UtilsActions } from "store/ducks/utils";
import localForage from "localforage";
import SearchInput from "components/SearchInput";
import SelectCategoria from "components/SelectCategoria";
import BeneficioItemList from "components/BeneficioItemList";
import useDebounce from "helpers/useDebounce";
import { ErrorMessage } from "./styles";

const BeneficiosArchive = ({ location, ...rest }) => {
  const [beneficios, setBeneficios] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const { toogleLoading } = UtilsActions;

  const debouncedInputs = useDebounce(inputs, 500);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get("q");
    api.get("beneficios/v1/categorias").then(({ data }) => setCategorias(data));
    if (query) {
      setInputs({ query });
      return;
    }
    api.post("beneficios/v1/list").then(({ data }) => {
      setBeneficios(data);
      localForage.setItem("agecefce_beneficios", data);
    });
  }, [location.search]);

  useEffect(() => {
    if (debouncedInputs) {
      const searchBeneficios = ({ query, categoria }) => {
        setError(false);
        dispatch(toogleLoading());
        api
          .post("beneficios/v1/list", { query, categoria })
          .then(({ data }) => {
            dispatch(toogleLoading());
            setBeneficios(data);
            localForage.setItem("agecefce_beneficios", data);
          })
          .catch(() => {
            dispatch(toogleLoading());
            setError(true);
          });
      };
      searchBeneficios(debouncedInputs);
    } else {
      // setResults([]);
    }
  }, [debouncedInputs, dispatch, toogleLoading]);

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Header title="Benefícios" {...rest} interna />
      <SearchInput handleInputs={handleInputs} value={inputs.query} />
      <SelectCategoria
        handleInputs={handleInputs}
        value={inputs.categoria}
        categorias={categorias}
      />
      {error && (
        <ErrorMessage>Não conseguimos encontrar este benefício</ErrorMessage>
      )}
      <BeneficioItemList beneficios={beneficios} />
    </>
  );
};

export default BeneficiosArchive;
